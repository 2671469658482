import {inject} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import qs from "qs";
import useTools from "../General/useTools";
import ConcurrentRequest from "@/plugins/ConcurrentRequest";
import {VueCookieNext} from "vue-cookie-next";
import {useStore} from "vuex";

const globState = {
  language: null,
  nextLang: null,
  pid: null
}
const loadReq = new ConcurrentRequest('translations');

const useTranslations = () => {
  const api = inject("$api");


  const store = useStore();
  const toast = useToast();
  const {generateErrorMessage} = useTools();
  const {t, setLocaleMessage, locale} = useI18n();

  const loadTranslations = (lang, force = false) => {
    let pid = VueCookieNext.getCookie('pid');

    const defaultParams = {
      filter: {
        section: "web_user"
      }
    };

    if (loadReq.initialized && (globState.nextLang !== lang || globState.pid !== pid)) {
      loadReq.reset();
    }
    globState.nextLang = lang
    globState.pid = pid

    return loadReq.doRequest(() => api
      .get(`/ui-translations/${lang.replaceAll("-", "_")}`, {
        params: {
          ...defaultParams,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {encode: false});
        },
      })
    )
      .then((resp) => {
        if (resp.status === 200) {
          if (globState.nextLang === lang) {
            setLocaleMessage(lang, resp?.data);
            locale.value = lang;
            globState.language = lang;

            store.commit("cacheLanguage", {values: resp?.data, lang, pid});
          }
          return true;
        }
      })
      .catch((err) => {
        const error = generateErrorMessage(err?.response?.data);
        toast.error(error || t("translations-load-error"));
        return false;
      });
  };

  return loadTranslations;
};

export default useTranslations;
