export default function useMarkdown() {
    const makeLinksAbsolute = (md) => {
        function isAbsolute(url) {
            return url.startsWith('http://') || url.startsWith('https://');
        }

        md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
            const token = tokens[idx];
            const hrefIndex = token.attrIndex('href');
            if (hrefIndex >= 0) {
                const hrefAttr = token.attrs[hrefIndex];
                const url = hrefAttr && hrefAttr[1];
                if (url && !isAbsolute(url)) {
                    hrefAttr[1] = 'https://' + url;
                }
            }
            return self.renderToken(tokens, idx, options);
        };
    }

    return {
        makeLinksAbsolute,
    }
}