import { inject, reactive } from "vue";
import { useStore } from "vuex";

export default function useValues() {
  const api = inject("$api");
  let values = reactive({});
  const store = useStore();

  const loadValues = () => {
    return api
      .get("/user/values")
      .then((response) => {
        if (response.status === 200) {
          Object.assign(values, response.data.data);
          store.commit("setValues", Object.assign({}, values));
          return true;
        }
      })
      .catch((error) => {
        return false;
      });
  };

  return {
    loadValues,
  };
}
