import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
// import {nextTick} from "vue";
import { VueCookieNext } from "vue-cookie-next";
import i18n from "@/plugins/IbiolaTranslation";
import store from "../store/index";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  const providerName = store.getters?.getProvider?.name;
  let title = i18n.global.t('page-title-default');
  if (to.meta.title) {
    title = i18n.global.t(to.meta.title, { id: to.params?.id });
  }

  if (providerName) {
    title += " | " + providerName;
  }

  document.title = title;
});

router.beforeEach((to, from, next) => {
  if (to.query.pid) {
    VueCookieNext.setCookie("pid", to.query.pid);
    if (!to.meta.requiresAuth) {
      next({
        name: "splash",
        query: { redirect: to.fullPath },
      });
      return;
    }
  }

  if (to.meta.requiresAuth && !VueCookieNext.isCookieAvailable("auth")) {
    // If route requires auth and user is not authed - redirect to login
    next({
      path: "/",
      // query: {redirect: to.fullPath}
    });
  } else {
    if (to.name === "login" && VueCookieNext.isCookieAvailable("auth")) {
      next({
        path: "/dashboard",
      });
    }
    next();
  }
});
/*
// Checks if jwt is set from the app, waits some time because the view might be mounted but the js not injected
function checkJwt() {
    return new Promise((resolve, reject) => {
        let maxExecution = 50;
        setTimeout(() => {
            if (VueCookieNext.isCookieAvailable('access_token')) {
                clearTimeout(this);
                return resolve();
            }
            maxExecution--;

            if (maxExecution <= 0) {
                return reject();
            }
        }, 100);
    });
}

function checkLoggedIn(to, from, next) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (to.name !== 'Login' && !VueCookieNext.isCookieAvailable('access_token')) {// && !store.getters.accessToken

            next({
                path: '/login',
                query: {redirect: to.fullPath}
            });

        } else {
            if (to.meta.permission === undefined) {

                next();
            } else {
                checkUserHasPermission(to.meta.permission)
                    .then(() => {
                        next();
                    })
                    .catch(() => {
                        next({
                            path: '/dashboard',
                            query: {redirect: to.fullPath}
                        });
                    });
            }
        }
    } else {
        next(); // does not require auth, make sure to always call next()!

    }
}

function checkUserHasPermission(checkPermission) {
    return new Promise((resolve, reject) => {
        console.log(checkPermission);
        // eslint-disable-next-line no-constant-condition
        if ('true' === 'true') {

            return resolve();
        }
        return reject();
        //     let userId = null;
        //     if (Vue.$cookies.isKey('access_token')) {
        //         const token = jwtDecode(Vue.$cookies.get('access_token'));
        //         userId = token.sub;
        //     } else {
        //         reject();
        //     }
        //
        //     return User.find(userId)
        //         .then(user => {
        //             user.roles.forEach(role => {
        //                 if (['superadmin', 'developer'].includes(role.name)) {
        //                     return resolve();
        //                 }
        //             });
        //             user.permissions.forEach(permission => {
        //                 if (checkPermission === permission.name) {
        //                     return resolve();
        //                 }
        //             });
        //             return reject();
        //         });
    });
}
*/

export default router;
