
const LANGUAGE_CACHE_LIFETIME = 60 * 60 * 24 // 1 Day
const state = () => {
    return {
        pid: null,
        timestamp: null,
        lang: null,
        values: null,
    };
};

const mutations = {

    cacheLanguage(state, {values, lang, pid, timestamp = null}) {
        if (timestamp === null || timestamp === undefined) {
            timestamp = Math.floor(Date.now() / 1000);
        }

        state.values = values;
        state.lang = lang;
        state.pid = pid;
        state.timestamp = timestamp;
    },

    invalidateLangCache(state) {
        state.timestamp = null
    },

    deleteLangCache(state) {
        state.pid = null;
        state.timestamp = null;
        state.lang = null;
        state.value = null;
    },
}

const getters = {

    getLangValues(state) {
        return state.values;
    },

    getLangTimestamp(state) {
        return state.timestamp
    },

    getCachedLang(state) {
        return state.lang;
    },

    getLangPid(state) {
        return state.pid;
    },

    isLangValid(state) {
        return state.timestamp &&
            (state.timestamp + LANGUAGE_CACHE_LIFETIME) >= Math.floor(Date.now() / 1000);
    },

    hasLangCached(state) {
        return state.lang && state.values;
    }
}

export default {
    state,
    mutations,
    getters,
}