/**
 * Utility class to keep multiple components from doing the same request
 */
class ConcurrentRequest {
    #done;
    #promise;
    debugName;

    constructor(name = null) {
        this.#done = false;
        this.#promise = null;
        this.debugName = name
    }

    get done() {
        return this.#done
    }

    get initialized() {
        return !!this.#promise
    }

    reset() {
        this.#done = false;
        this.#promise = null;
        if(this.debugName) {
            console.debug(`Concurrent Request [${this.debugName}]: Reset`)
        }
    }

    /**
     * handler will be called once to generate a promise.
     * The same promise is returned for all calls to this method.
     * If redo is true the handler will be called again if (and only if) the previous promise is done, otherwise it will have no effect
     * @param handler Handler to generate a new promise
     * @param redo Redo the request if previous ones have finished
     * @returns {*} The Promise to wait for the request
     */
    doRequest(handler, redo = false) {
        if(!this.#promise || (redo && this.#done)) {
            const promise = handler();
            if (promise) {
                let doneFunc = () => {
                    if(this.#promise !== promise) {
                        // Exit if this is a lingering promise after a reset
                        return
                    }

                    this.#done = true
                    this.#debugLog(`Promise finished`);
                }

                this.#promise = promise;
                this.#promise.then(doneFunc, doneFunc);
                this.#debugLog(`Promise handler called`);
            }
            else {
                this.#debugLog(`Promise handler returned null/undefined`);
            }
        }
        else {
            this.#debugLog(`Promise already waiting ( done = ${this.#done} )`)
        }

        return this.#promise
    }

    #debugLog(msg) {
        if(this.debugName) {
            console.debug(`Concurrent Request [${this.debugName}]: ${msg}`);
        }
    }
}

export default ConcurrentRequest;