import {computed, onMounted, onUnmounted, ref} from "vue";
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';

export default function useBreakpoints() {
    const fullConfig = resolveConfig(tailwindConfig)
    let windowWidth = ref(window.innerWidth);

    let tailwindBreakpoints = fullConfig.theme.screens;
    let breakpointLg = ref(null);

    if (tailwindBreakpoints && tailwindBreakpoints.lg) {
        const bp = tailwindBreakpoints.lg.replace('px', '');
        breakpointLg.value = Number.parseInt(bp);
    }

    const onWidthChange = () => {
        windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
        window.addEventListener('resize', onWidthChange);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', onWidthChange);
    });

    const isLg = computed(() => {
        return windowWidth.value > breakpointLg.value;
    });
    const isMobileView = computed(() => {
        return windowWidth.value < breakpointLg.value;
    });

    const width = computed(() => {
        return windowWidth.value;
    });

    return {
        width,
        isLg,
        isMobileView,
    };
}