import {inject, onBeforeMount, reactive} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {VueCookieNext} from "vue-cookie-next";
import useTranslations from "@/composables/General/useTranslations";
import ConcurrentRequest from "@/plugins/ConcurrentRequest";
import {useToast} from "vue-toastification";
import useTools from "@/composables/General/useTools";

const globState = {
  user: false
}
const loadReq = new ConcurrentRequest('user');

export default function useUser() {
  const {locale} = useI18n({useScope: 'global'});
  const loadTranslations = useTranslations();
  const {generateErrorMessage} = useTools();
  const api = inject('$api');

  let user = reactive({});


  const store = useStore();
  const toast = useToast();
  const {t} = useI18n();

  onBeforeMount(() => {
    store.getters.getDesign;
  });


  const load = (forceTranslation = false, forceReload = false, params) => {
    return new Promise((resolve, reject) => {
      let storeUser = null;

      if (!globState.user) {
        forceReload = true
      }

      console.log(`Load User: language=${forceTranslation} reload=${forceReload}`)

      if (store.getters.getUser) {
        storeUser = store.getters.getUser;
        locale.value = storeUser.details.language;
        const tmpUser = {};
        Object.assign(tmpUser, storeUser);
        tmpUser.birth = new Date(tmpUser.birth);

        if (!forceTranslation && !forceReload) {
          //FIXME: Shouldn't load every time!
          console.log("Early User return")
          return loadTranslations(tmpUser.details.language).then(() => resolve(tmpUser));
        }
      }

      if (storeUser == null) {
        VueCookieNext.removeCookie('auth');
        return reject('User not logged in');
      }

      return loadReq.doRequest(() => api.get('/users/' + storeUser.id, {
          params: {...params}
        })
          .then(async (response) => {
            if (response.status === 200) {
              await loadTranslations(response.data.data.details.language, forceTranslation);
            }
            console.log("User reloaded")
            globState.user = true
            return response;
          }), forceReload
      )
        .then(async (response) => {
          if (response.status === 200) {

            Object.assign(user, response.data.data);
            user.birth = new Date(user.birth);
            locale.value = user.details.language;


            store.commit('setUser', Object.assign({}, user));
            await loadTranslations(user.details.language, forceTranslation);
          }
          console.log("User reloaded")
          globState.user = true
          return resolve(user);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const update = (id, data) => {
    return new Promise((resolve, reject) => {
      return api.put('/users/' + id, data)
        .then(async (response) => {
          if (response.status === 200) {
            const updatedUser = response.data.data;

            Object.assign(user, updatedUser);
            user.birth = new Date(user.birth);
            locale.value = user.details.language;
            store.commit('setUser', Object.assign({}, user));
            await loadTranslations(user.details.language);

            return resolve(updatedUser);
          }
          return reject();
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const loadPaymentAccount = () => {
    return new Promise((resolve, reject) => {
      let storeUser = null;

      if (store.getters.getUser) {
        storeUser = store.getters.getUser;
      }
      if (storeUser == null) {
        return reject('User not logged in');
      }

      return api.get('/users/' + storeUser.id + '/payment-accounts?include=paymentAccount&append[paymentAccount]=isOwner')
        .then(response => {
          let paymentAccount = {};
          if (response.status === 200 && response.data.meta.pagination.total > 0) {
            paymentAccount = response.data.data[0].paymentAccount;
          }

          return resolve(paymentAccount);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const loadPaymentAccounts = () => {
    return new Promise((resolve, reject) => {
      let storeUser = null;

      if (store.getters.getUser) {
        storeUser = store.getters.getUser;
      }
      if (storeUser == null) {
        return reject('User not logged in');
      }

      return api.get('/users/' + storeUser.id + '/payment-accounts?include=paymentAccount&append[paymentAccount]=isOwner')
        .then(response => {
          let paymentAccountList = [];
          if (response.status === 200 && response.data.meta.pagination.total > 0) {
            paymentAccountList = response.data.data;
          }

          return resolve(paymentAccountList);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const createPaymentAccount = (data) => {
    return new Promise((resolve, reject) => {
      let storeUser = null;

      if (store.getters.getUser) {
        storeUser = store.getters.getUser;
      }
      if (storeUser == null) {
        return reject('User not logged in');
      }
      return api.post('/users/' + storeUser.id + '/payment-accounts', data)
        .then(response => {
          let paymentAccount = {};
          if (response.status === 200 && response.data.data) {
            paymentAccount = response.data.data.paymentAccount;
          }

          return resolve(paymentAccount);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const updatePaymentAccount = (id, data) => {
    return new Promise((resolve, reject) => {
      return api.put('/payment-accounts/' + id, data)
        .then(response => {
          let paymentAccount = {};
          if (response.status === 200 && response.data.data) {
            paymentAccount = response.data.data.paymentAccount;
          }

          return resolve(paymentAccount);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const createUpload = (upload) => {
    return new Promise((resolve, reject) => {
      return api.post('/uploads', upload)
        .then(response => {
          let upload = {};
          if (response.status === 200 && response.data.data) {
            upload = response.data.data;
          }

          return resolve(upload);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  const providerSelect = (pid) => {
    return new Promise((resolve, reject) => {
      return api.post('/user/change-provider', {providerId: pid})
        .then(response => {
          if (response.status === 200) {
            store.commit('setToken', response.data.data.token);
            store.commit('initHelp');

            resolve(true);
          }

          resolve(true);
        })
        .catch((error) => {
          return reject(error);
        });
    })
  };

  const refreshAuthenticated = () => {
    return new Promise((resolve, reject) => {
      store.commit('initBooking');

      return api.get('/user')
        .then(response => {
          const updatedUser = {};
          if (response.status === 200) {
            store.commit('initDesign');
            Object.assign(updatedUser, response.data.data.user);

            updatedUser.birth = new Date(updatedUser.birth);
            locale.value = updatedUser.details.language;

            store.commit('setUser', updatedUser);
            store.commit('setProviderConfiguration', Object.assign({}, response.data.data.configuration));
            store.commit('setProvider', Object.assign({}, response.data.data.provider));
            store.commit('setProviderList', Object.assign({}, response.data.data.providers));

            // Update cookies
            VueCookieNext.setCookie('auth', '1', {expire: '30d'});
            VueCookieNext.setCookie('pid', response.data.data.provider.id, {expire: '31d'});
            if (VueCookieNext.isCookieAvailable('tral')) {
              VueCookieNext.setCookie('tral', '1', {expire: '31d'});
            }
          }

          resolve(updatedUser);
        })
        .catch((error) => {
          return reject(error);
        });
    })
  };

  const requestAccountDeletion = (feedback) => {
    return new Promise((resolve, reject) => {

      return api.post('/auth/user/request-deletion', {feedback})
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          return reject(error);
        });
    })
  };


  const loadStickyMessages = () => {
    return new Promise((resolve, reject) => {
      return api.get('/user/sticky-messages')
        .then(response => {
          if (response.status === 200) {
            store.commit('setStickyMessages', response.data.data);

            resolve(true);
          } else {
            store.commit('setStickyMessages', null);
          }

          resolve(true);
        })
        .catch((error) => {
          store.commit('setStickyMessages', null);
          return reject(error);
        });
    })
  };

  const loadNotifications = () => {
    return new Promise((resolve, reject) => {
      let storeUser = null;

      if (store.getters.getUser) {
        storeUser = store.getters.getUser;
      }
      if (storeUser == null) {
        return reject('User not logged in');
      }

      function forceDoubleBreaks(content) {
        return content.replace(/\n+/g, '<br><br>');
      }


      return api.get('/user/notifications')
        .then(response => {
          let notifications = {};
          if (response.status === 200) {
            notifications = response.data.data;
            for (let notification of notifications) {
              notification.content = forceDoubleBreaks(notification.content);
            }

            // console.log("notifications modified",notifications)
            let count = 0;
            notifications.forEach((notification) => {
              if (!notification.read) {
                count++;
              }
              notification.visible.from = new Date(notification.visible.from)
            })
            store.commit('setNotifications', {data: notifications, count: count});
          }

          return resolve(notifications);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const setNotificationRead = (id) => {
    return new Promise((resolve, reject) => {
      api.put('/user/notifications/' + id, {setMessageRead: true})
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            resolve(response);
            loadNotifications()

          } else {
            reject(new Error(`Request failed with status ${response.status}`));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const loadInvoices = (page) => {
    return new Promise((resolve, reject) => {
      let storeUser = null;

      if (store.getters.getUser) {
        storeUser = store.getters.getUser;
      }
      if (storeUser == null) {
        return reject('User not logged in');
      }

      return api.get('/invoices/', {
        params: {
          include: 'upload',
          sort: '-generated',
          page: page || 1,
        }
      })
        .then(response => {

          return resolve(response.data);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const downloadInvoice = (invoice) => {
    return new Promise((resolve, reject) => {


      return api
        .post(`/invoices/${invoice.id}/actions`, {
          type: "download",
          responseType: "blob",
        })
        .then((response) => {
          let blob = new Blob([response.data], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `invoice-${invoice.billNumber}.pdf`;
          link.click();
          resolve();
        })
        .catch(() => {
          toast.error(t('invoice-error-while-download'));
          reject();
        });
    });
  };

  const drivingLicenceVerification = (redirect = false) => {
    return new Promise((resolve, reject) => {
      return api
        .post('/user/driving-licence-verification', {
          redirectProfile: redirect,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const loadCostUploads = (page) => {
    return new Promise((resolve, reject) => {
      return api.get('/user/cost-uploads/', {
        params: {
          include: 'upload',
          sort: '-id',
          page: page || 1,
        }
      })
        .then(response => {
          return resolve(response.data);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const removeCostUpload = (id) => {
    return api.delete('/user/cost-uploads/' + id)
      .then((response) => {
        return response.status === 200;
      })
      .catch((err) => {
        const error = generateErrorMessage(err?.response?.data);
        toast.error(error || t("invoice-upload-delete-error"));
        return false;
      });
  };

  return {
    load,
    update,
    loadPaymentAccount,
    loadPaymentAccounts,
    createPaymentAccount,
    updatePaymentAccount,
    createUpload,
    refreshAuthenticated,
    providerSelect,
    requestAccountDeletion,
    loadStickyMessages,
    loadNotifications,
    setNotificationRead,
    loadInvoices,
    downloadInvoice,
    drivingLicenceVerification,
    loadCostUploads,
    removeCostUpload,
  }
}
