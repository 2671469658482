import { createStore } from "vuex";
import App from "./modules/App";
import User from "./modules/User";
import Booking from "./modules/Booking";
import Bluetooth from "./modules/Bluetooth";
import Language from "./modules/Language";
import Values from "./modules/Values";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

// eslint-disable-next-line no-unused-vars
let ls = new SecureLS({ isCompression: false }); // For Obfuscating
const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    App,
    User,
    Booking,
    Bluetooth,
    Language,
    Values,
  },

  strict: debug,
  plugins: [
    createPersistedState({
      // key: 'ibiola',
      // storage: {
      //     getItem: (key) => ls.get(key),
      //     setItem: (key, value) => ls.set(key, value),
      //     removeItem: (key) => ls.remove(key),
      // },
    }),
  ],
});
