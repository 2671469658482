export default function useDesign() {
    const ibiolaPrimaryScheme = {
        50: '#E1EAFE',
        100: '#C8D9FD',
        200: '#96B7FC',
        300: '#6594FA',
        400: '#3472F8',
        500: '#0852F1',
        600: '#0640BB',
        700: '#042D84',
        800: '#031B4E',
        900: '#010818',
    };

    const timPrimaryScheme = {
        50: '#ECF7CD',
        100: '#E3F4B7',
        200: '#D2ED8B',
        300: '#C1E65F',
        400: '#B0DF33',
        500: '#94C11E',
        600: '#729517',
        700: '#506910',
        800: '#2E3D09',
        900: '#0D1003',
    };

    const setDefault = () => {
        const hostname = location.hostname;

        if (hostname.includes('.tim-graz.at') || hostname.includes('.tim-zentralraum.at') || hostname.includes('.tim-linz.at')) {
            setPrimary(timPrimaryScheme);
        } else {
            setPrimary(ibiolaPrimaryScheme);
        }

        const grayScheme = {
            50: '#F9FAFB',
            100: '#F3F4F6',
            200: '#E5E7EB',
            300: '#D1D5DB',
            400: '#9CA3AF',
            500: '#6B7280',
            600: '#4B5563',
            700: '#374151',
            800: '#1F2937',
            900: '#111827',
        }
        setGray(grayScheme);

        const successScheme = {
            dark: '#064E3B',
            default: '#10B981',
            light: '#D1FAE5',
        }
        setSpecial('success', successScheme);

        const attentionScheme = {
            dark: '#987701',
            default: '#F9C716',
            light: '#FDF5D8',
        }
        setSpecial('attention', attentionScheme);

        const warningScheme = {
            dark: '#7C2D12',
            default: '#F97316',
            light: '#FFEDD5',
        }
        setSpecial('warning', warningScheme);

        const dangerScheme = {
            dark: '#7F1D1D',
            default: '#EF4444',
            light: '#FEE2E2',
        }
        setSpecial('danger', dangerScheme);

        const infoScheme = {
            dark: '#1E3A8A',
            default: '#3B82F6',
            light: '#DBEAFE',
        }
        setSpecial('info', infoScheme);
    };

    const setPrimaryFromConfiguration = (configScheme) => {
        if (configScheme) {
            Object.entries(configScheme).forEach(([shadeKey, shadeValue]) => {
                const key = shadeKey.substring(('primary-').length, shadeKey.length);
                setSchemeShade('primary', key, shadeValue);
            });
        } else {
            setDefault();
        }
    };

    const setPrimary = (colorScheme) => {
        setScheme('primary', colorScheme);
    };

    const setGray = (colorScheme) => {
        setScheme('gray', colorScheme);
    };

    const setScheme = (type, scheme) => {
        Object.entries(scheme).forEach(([shadeKey, shadeValue]) => {
            setSchemeShade(type, shadeKey, shadeValue);
        });
    };

    const setSchemeShade = (type, shade, color) => {
        document.documentElement.style.setProperty("--color-" + type + "-" + shade, color);
    };

    const setSpecial = (type, colorScheme) => {
        document.documentElement.style.setProperty("--color-" + type + "-light", colorScheme['light']);
        document.documentElement.style.setProperty("--color-" + type + "-default", colorScheme['default']);
        document.documentElement.style.setProperty("--color-" + type + "-dark", colorScheme['dark']);
    };

    return {
        setDefault,
        setPrimary,
        setPrimaryFromConfiguration,
        setSpecial,
    }
}