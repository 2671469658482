import i18nCountries from "i18n-iso-countries";
import {
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import {useI18n} from "vue-i18n";
import {computed} from "vue";
import {useStore} from "vuex";

export default function useTools() {
  const {locale} = useI18n({useScope: "global"});
  const store = useStore();

  const isEmptyString = (stringToCheck) => {
    if (
      stringToCheck === "" ||
      stringToCheck === null ||
      typeof stringToCheck === "undefined"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const resizeImage = (base64Str, maxWidth = 1000, maxHeight = 1000) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
    });
  };

  const getCountryList = (selectedOnTop = true) => {
    const lang = locale.value.substring(0, 2);
    let list = i18nCountries.getNames(lang);

    let countries = [];

    if (selectedOnTop) {
      countries.push({name: i18nCountries.getName("AT", lang), value: "AT"});
      countries.push({name: i18nCountries.getName("DE", lang), value: "DE"});
      countries.push({name: i18nCountries.getName("CH", lang), value: "CH"});
    }
    Object.entries(list).forEach((country) => {
      if (
        !selectedOnTop ||
        (selectedOnTop && !["AT", "DE", "CH"].includes(country[0]))
      ) {
        countries.push({name: country[1], value: country[0]});
      }
    });

    return countries;
  };

  const getPhoneCountryList = () => {
    let supportedCountries = getCountries();
    let countries = getCountryList(false);
    let phoneCountries = [];

    countries.forEach((country) => {
      if (supportedCountries.includes(country.value)) {
        phoneCountries.push({
          name: country.name,
          iso2: country.value,
          dialCode: getCountryCallingCode(country.value),
        });
      }
    });

    return phoneCountries;
  };

  const castPhoneNumber = (number, country = null) => {
    let phoneNumber;
    if (country !== null) {
      phoneNumber = parsePhoneNumber(number, country);
    } else {
      if (isValidPhoneNumber(number)) {
        phoneNumber = parsePhoneNumber(number);
      } else {
        phoneNumber = number;
      }
    }

    return phoneNumber;
  };

  const formatPhoneNumber = (number, country = null) => {
    if (number && number.trim() !== "") {
      const casted = castPhoneNumber(number, country);

      if (typeof casted === "object") {
        return casted.format("INTERNATIONAL");
      }

      return casted;
    }
    return null;
  };

  const formatPhoneNumberUrl = (number, country = null) => {
    if (number && number.trim() !== "") {
      return castPhoneNumber(number, country).format("RFC3966");
    }
    return null;
  };

  /*
   * Returns an object with all languages supported.
   *
   * foreach language:
   *   -) languagePretty: Language name in the current setted language (e.g. German)
   *   -) languageCodeLong: e.g.: de-DE
   *   -) languageCodeShort: e.g.: de
   *   -) countryCodeShort: e.g.: DE
   * */
  const makeSupportedLanguagesObject = computed(() => {
    const getSupportedLanguages = computed(() => {
      if (store.getters.getProviderLanguages) {
        return store.getters.getProviderLanguages.map((language) => language.value);
      } else if (store.state.User.authConfiguration) {
        return store.state.User.authConfiguration.languages;
      } else {
        return ["de-DE", "en-US", "fr-FR"];
      }
    });

    let res = [];
    getSupportedLanguages.value.forEach((language) => {
      let subRes = [];
      subRes.languagePretty = getLanguageName(getLanguageShort(language));
      subRes.languageCodeLong = language;
      subRes.languageCodeShort = getLanguageShort(language);
      subRes.countryCodeShort = getCountryShort(language);
      res.push(subRes);
    });
    return res;
  });

  // Translates a language of the language list into the current language
  // if parameter is e.g. de: return = Deutsch, German;
  // if parameter is e.g. de-DE return = Deutsch (Deutschland)
  const getLanguageName = (languageNameOf) => {
    const languageNames = new Intl.DisplayNames([locale.value], {
      type: "language",
    });
    return languageNames.of(getLanguageShort(languageNameOf));
  };

  // extracts 2 digit language code of 5 digit language-country code (de out of de-DE)
  const getLanguageShort = (languageCountryLong) => {
    return languageCountryLong?.substring(0, 2);
  };

  // extracts 2 digit country code of 5 digit language-country code (DE out of de-DE)
  const getCountryShort = (languageCountryLong, toLowerCase = false) => {
    let res = languageCountryLong.substring(3, 5);
    if (toLowerCase) {
      res = res.toLowerCase();
    }
    return res;
  };

  // Checks if Webview is in APP-shell
  const isNativeApp = () => {
    return !!(typeof window !== "undefined" && window.ReactNativeWebView);
  };

  const formatBytes = (bytes, decimals = 1) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    // console.log("formatBytes", parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i])
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const deepEqual = (x, y) => {
    const ok = Object.keys,
      isDate = Object.prototype.toString.call(x) === "[object Date]",
      tx = typeof x,
      ty = typeof y;
    return x && y && tx === "object" && !isDate && tx === ty
      ? ok(x).length === ok(y).length &&
      ok(x).every((key) => {
        return deepEqual(x[key], y[key]);
      })
      : isDate
        ? x.toString() === y.toString()
        : x === y;
  };

  const generateErrorMessage = (serverError) => {
    const error = {
      component: ({message, errors}) => {
        return (
          <div>
            {message}
            {errors && typeof errors === "object" && (
              <ul class="list-disc list-inside ml-2">
                {Object.values(errors).map((errGroup) =>
                  errGroup?.map((err) => <li>{err}</li>)
                )}
              </ul>
            )}
          </div>
        );
      },

      props: {
        message: serverError?.message,
        errors: serverError?.errors,
      },
    };

    return error;
  };

  const getFormattedCurrency = (amount) => {
    return new Intl.NumberFormat(locale.value, {
      style: 'currency',
      currency: store.getters.getProvider?.options?.currency
    })
      .format(amount);
  }

  const getFileEndingFromMimeType = (mimeType) => {
    if (mimeType.includes("image/png")) {
      return "png";
    }
    if (mimeType.includes("image/jpeg")) {
      return "jpeg";
    }
    if (mimeType.includes("application/pdf")) {
      return "pdf";
    }
    return "jpg";
  };

  return {
    isEmptyString,
    resizeImage,
    getCountryList,
    makeSupportedLanguagesObject,
    getPhoneCountryList,
    formatPhoneNumber,
    formatPhoneNumberUrl,
    getLanguageName,
    getLanguageShort,
    getCountryShort,
    isNativeApp,
    formatBytes,
    deepEqual,
    generateErrorMessage,
    getFormattedCurrency,
    getFileEndingFromMimeType,
  };
}
