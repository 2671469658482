const state = () => {
    return {
        tokenList: [],
        lastUsedList: [],
    }
};
const mutations = {
    initBluetooth(state) {
        state.tokenList = [];
    },

    updateToken(state, token) {
        if (token && token.bookingId) {
            if (!state.tokenList) {
                state.tokenList = [];
            }
            const tokenIndex = state.tokenList.findIndex(x => {
                if (x && x.bookingId) {
                    return x.bookingId === token.bookingId;
                }
                return false;
            });
            if (tokenIndex !== -1) {
                state.tokenList[tokenIndex] = token;
            } else {
                state.tokenList.push(token);
            }
        }
    },

    removeToken(state, token) {
        if (token && token.bookingId) {
            if (!state.tokenList) {
                state.tokenList = [];
            }
            const tokenIndex = state.tokenList.findIndex(x => {
                if (x && x.bookingId) {
                    return x.bookingId === token.bookingId;
                }
                return false;
            });
            if (tokenIndex !== -1) {
                delete state.tokenList[tokenIndex];
            }
        }
    },

    usedToken(state, token) {
        if (token && token.bookingId) {
            const tokenIndex = state.tokenList.findIndex(x => {
                if (x && x.bookingId) {
                    return x.bookingId === token.bookingId;
                }
                return false;
            });
            const data = {
                id: token.bookingId,
                passkey: token.passkey,
            };
            if (tokenIndex !== -1) {
                state.lastUsedList[tokenIndex] = data;
            } else {
                state.lastUsedList.push(data);
            }
        }
    }

};
const getters = {
    getBluetoothForBooking: (state) => (bookingId) => {
        if (state.tokenList && bookingId) {
            return state.tokenList.find(x => {
                if (x && x.bookingId) {
                    return x.bookingId === bookingId;
                }

                return false;
            });
        }
        return null;
    },
};

export default {
    state,
    mutations,
    getters,
}