import useDesign from "@/composables/General/useDesign";

const {setPrimaryFromConfiguration, setDefault} = useDesign();

const state = () => {
    return {
        token: null,
        user: null,
        provider: null,
        providerConfiguration: null,
        authConfiguration: null,
        design: null,
        help: null,
        providerList: null,
        stickyMessages: null,
        notifications: [],
        notificationsCount: 1,
    }
};
const mutations = {
    resetStore(state) {
        state.token = null;
        state.user = null;
        state.provider = null;
        state.providerConfiguration = null;
        state.authConfiguration = null;
        state.design = null;
        state.help = null;
        state.providerList = null;
        state.stickyMessages = null;
        state.notifications= null;
        state.notificationsCount = 1;
        setDefault();
    },

    initUser(state, data = {themeReset: true}) {
        state.token = null;
        state.user = null;
        state.provider = null;
        state.providerConfiguration = null;
        state.help = null;
        state.providerList = null;
        state.stickyMessages = null;
        state.notifications = null;
        state.notificationsCount = 1;
        if (data.themeReset) {
            state.design = null;
            setDefault();
        }
    },

    initDesign(state) {
        state.design = null;
        setDefault();
    },

    initHelp(state) {
        state.help = null;
    },

    setToken(state, token) {
        state.token = token;
    },

    setUser(state, user) {
        state.user = user;
    },

    setNotifications(state, notification) {
        state.notifications = notification.data
        state.notificationsCount = notification.count
    },


    setProvider(state, provider) {
        state.provider = provider;
        if (provider && provider.design) {
            state.design = provider.design;
            setPrimaryFromConfiguration(state.design);
        } else {
            setDefault();
        }
    },

    setProviderConfiguration(state, configuration) {
        state.providerConfiguration = configuration;
    },

    setAuthConfiguration(state, configuration) {
        state.authConfiguration = configuration;
        if (configuration && configuration.design) {
            state.design = configuration.design;
            setPrimaryFromConfiguration(state.design);
        } else {
            setDefault();
        }
    },

    setDesign(state, design) {
        state.design = design;
    },

    setHelp(state, help) {
        state.help = help;
    },

    setProviderList(state, providerList) {
        state.providerList = providerList;
    },

    setStickyMessages(state, stickyMessages) {
        state.stickyMessages = stickyMessages;
    },
};
const getters = {
    getToken(state) {
        return state.token;
    },

    getUser(state) {
        return state.user;
    },

    getNotifications(state) {
      return state.notifications;
    },

    getNotificationsCount(state) {
      return state.notificationsCount;
    },

    getProvider(state) {
        return state.provider;
    },

    getProviderConfiguration(state) {
        const configuration = state.providerConfiguration;
        if (configuration && configuration.design) {
            setPrimaryFromConfiguration(state.design);
        }
        return state.providerConfiguration;
    },

    getAuthConfiguration(state) {
        const configuration = state.authConfiguration;
        if (configuration && configuration.design) {
            setPrimaryFromConfiguration(state.design);
        }
        return state.authConfiguration;
    },

    getDesign(state) {
        setPrimaryFromConfiguration(state.design);
        return state.design;
    },

    getHelp(state) {
        return state.help;
    },

    getProviderList(state) {
        return state.providerList;
    },

    getStickyMessages(state) {
        return state.stickyMessages;
    },
};

export default {
    state,
    mutations,
    getters,
}