<template>
  <router-view/>
</template>

<style>
* {
  scrollbar-width: auto;
  scrollbar-color: var(--color-primary-300) #ffffff;
}

*:hover {
  scrollbar-width: auto;
  scrollbar-color: var(--color-primary-500) #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-300);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-primary-500);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

</style>
