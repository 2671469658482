import { createApp } from "vue";
import App from "./App.vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// Plugins
import router from "./router";
import { VueCookieNext } from "vue-cookie-next";
import axios from "axios";
import { Cache } from "axios-extensions";
import { DateTime, Settings } from "luxon";
import VueCountdown from "@chenfengyuan/vue-countdown";
import {
  // Directives
  VTooltip,
  VClosePopper,
  // Components
  Dropdown,
  Tooltip,
  Menu,
} from "v-tooltip";
import "@formatjs/intl-displaynames/polyfill";
import "@formatjs/intl-displaynames/locale-data/en";
import "@formatjs/intl-displaynames/locale-data/de";
import "@formatjs/intl-locale/polyfill"

// Start configuration of API
// Create own cache, which will be exported and can be reset on logout
// const FIVE_MINUTES = 1000 * 60 * 5;
const TWENTY_MINUTES = 1000 * 60 * 20;
const cache = new Cache({ maxAge: TWENTY_MINUTES, max: 100 });

const instance = axios.create({
  withCredentials: true,
});
import IbiolaApi from "./plugins/Api";

const api = new IbiolaApi();
api.init(instance);

import store from "@/store";
import setupInterceptors from "@/plugins/ApiInterceptors";

setupInterceptors(instance, cache, store);

// Start configuration of i18n
import i18n from "@/plugins/IbiolaTranslation";

// const ibiolaTranslation = new IbiolaTranslation();
// const messages = IbiolaTranslation.getMessages();

// const i18n = createI18n({
//     legacy: false, // you must set `false`, to use Composition API
//     locale: 'de-DE', // set locale
//     fallbackLocale: 'en-US', // set fallback locale
//     messages, // set locale messages
// });

// Luxon Setting
Settings.defaultLocale = "de-DE";

// Set accept language header to default language
instance.defaults.headers["Accept-Language"] = i18n.global.locale.value;

import "@/plugins/Countries";

// CSS
import "./assets/css/index.css";
import "v-tooltip/dist/v-tooltip.css";

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faUserSecret } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import { FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";

import "./plugins/FontAwesome.js";
import "./registerServiceWorker";

const app = createApp(App);
if (process.env.NODE_ENV === "production") {
  let env = "production";
  let version = process.env.VUE_APP_VERSION;
  if (
    location.hostname.includes("ibiola-testing") ||
    location.hostname.includes("app-test")
  ) {
    env = "testing";
  }
  Sentry.init({
    app,
    dsn: "https://c7ec21cdb85e46f8aa38c1c6913707e4@sentry.ibiola.com/3",
    environment: env,
    release: "web-user@" + version,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
  });
}

// Toast Notifications
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
// import Position for Toast
const toastOptions = {
  // You can set your default options here
  position: POSITION.TOP_RIGHT,
};

app.use(Toast, toastOptions);

// Add usage
app.use(router);
app.use(VueCookieNext);
app.use(i18n);
app.use(store);
app.component(VueCountdown.name, VueCountdown);
// Font Awesome
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("font-awesome-layers", FontAwesomeLayers);
app.component("font-awesome-layers-text", FontAwesomeLayersText);
// Tooltip
app.directive("tooltip", VTooltip);
app.directive("close-popper", VClosePopper);
app.component("VDropdown", Dropdown);
app.component("VTooltip", Tooltip);
app.component("VMenu", Menu);

// Add globalProperties
app.provide("$api", instance);
app.provide("$cache", cache);
app.provide("$luxon", DateTime);

// Mount App
app.mount("#app");
