const state = () => {
  return {
    version: process.env.VUE_APP_VERSION || null,
    lastPing: null,
    apiStatus: 'online',
  }
};

const mutations = {
  refreshVersion(state) {
    state.version = process.env.VUE_APP_VERSION || null;
  },
  updatePing(state) {
    state.lastPing = new Date().getTime();
  },
  setApiOnline(state) {
    state.lastPing = new Date().getTime();
    state.apiStatus = 'online';
  },
  setApiOffline(state) {
    state.lastPing = new Date().getTime();
    state.apiStatus = 'offline';
  },
};

const getters = {
  getAppVersion: (state) => {
    return state.version;
  },
  lastPing: (state) => {
    return state.lastPing;
  },
  isApiOnline: (state) => {
    return state.apiStatus === 'online';
  },
  isApiOffline: (state) => {
    return state.apiStatus === 'offline';
  }
};

export default {
  state,
  mutations,
  getters,
}
