<template>
  <router-view></router-view>
</template>

<script>
import {useStore} from "vuex";
import {onBeforeMount, onMounted} from "vue";
import {VueCookieNext} from "vue-cookie-next";
import useTranslations from "@/composables/General/useTranslations";
import {useI18n} from "vue-i18n";

export default {
  name: "Unauthenticated",
  setup() {
    const loadTranslations = useTranslations();
    const {locale} = useI18n();
    const store = useStore();
    onBeforeMount(() => {
      if (!store.getters.getAuthConfiguration && !VueCookieNext.isCookieAvailable('auth')) {
        store.commit('initDesign');
      }
    });

    onMounted(() => {
      if (!VueCookieNext.isCookieAvailable('auth')) {
        loadTranslations(locale.value);
      }
    })
  }
}
</script>

<style scoped>

</style>
